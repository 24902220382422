import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Rules = () => (
  <Layout>
    <SEO title="Tesla2024 Rules" />
    <h2>Tesla2024 Rules</h2>
    <p>
      An investment experiment like Tesla2024 needs a well-constructed set of rules. This is a first attempt at the rules, so they could change by the end of the experiment. Here are the rules.
    </p>
    <div>
    <ol>
        <li>60 Months Starting in May 2019 - The experiment will go for 60 months starting in May 2019.</li>
        <li>At Least One Share of TSLA Each Month* - I will purchase at least one share of TSLA stock each month. The asterisk is in the case that TSLA is over $700 a share. In that case, I will have to invest every other month. That would be a great problem to encounter.</li>
        <li>Can Buy Shares of Up to 10 Companies - To start the experiment, I’m including investments from three companies: Tesla (TSLA), Shopify (SHOP), The Trade Desk (TTD). Over the course of the experiment, I can invest in up to 10 different companies. Try to identify companies that will be better off five years from now. Tesla should account for at least 33% of your holdings in Tesla2024 related investments.</li>
        <li>Average $700 Monthly Investment - I went to Tesla's Model 3 purchase page on May 7, 2019 and priced two models on a 60-month loan. The Standard Range Plus shows an estimated Loan Payment of $662 and the Long Range model shows $847. I'd probably purchase the lower end model if I had to right now so I'll try to invest at least $700 a month.</li>
        <li>Escape Hatch - I reserve the right to halt the experiment at any time for any reason.</li>
        <li>Share Details of Each Stock Purchase Related to Tesla2024 - I will publish all the trades I complete related to Tesla2024.</li>
        <li>Monthly Progress Posts - Produce a monthly progress post each month of the experiment.</li>
        <li>Rules Are Subject to Change - The rules of the experiment are subject to change. I want to do as well as possible and by keeping the rules flexible, I improve my odds of having a successful experiment. All rule changes must be published to the home page and old rules will be archived and linked from the active rules page. Rules take effect for the next month.</li>
        <li>Monetize Where Possible - Use the site to share ads and referral links. The site exists to share my experiment with the world and to possibly make a little money. I don’t know if anyone cares about my journey to acquiring a Tesla, but if they do and want to support me there will be ads. I’m also open to sponsored posts that make sense for the Tesla2024 brand.</li>
        <li>Must Buy a Vehicle in 2024 - Assuming people still drive cars and there’s a valid need to have one, I will buy a car in 2024. The model purchased depends on the performance of my investments and what happens in the world between now and then. The intention is for this car to be a Tesla, most likely a Model 3.</li>
      </ol>
    </div>
    <Link to="/">I Will Link All Past Rules Posts as the Rules Change</Link>
  </Layout>
)

export default Rules